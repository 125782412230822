<script lang="ts" setup></script>

<template>
  <div>
    <div class="text-center text-lg md:text-2xl">ขออภัย! คำขอไม่ถูกต้อง (400 Bad Request)</div>
    <div class="text-center text-sm md:text-lg text-grey-4">เราไม่สามารถประมวลผลคำขอของคุณได้<br/>เนื่องจากมีข้อผิดพลาดที่เกิดขึ้นในการส่งคำขอ</div>
    <v-alert
      type="info"
      color="grey-4"
      text="กรุณาตรวจสอบ URL หรือพารามิเตอร์ของคำขอและลองอีกครั้ง หากปัญหายังคงอยู่ โปรดติดต่อทีมงานเรา"
      variant="outlined"
      class="mt-2 text-sm"
    ></v-alert>
  </div>
</template>

<style scoped></style>
